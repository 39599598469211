<template>
  <div>
    <div v-if="stillLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>
    <div v-else class="min-h-screen">
      <div class="mb-6 flex justify-between items-center">
        <h1 class="text-3xl font-bold">Report Ecommerce</h1>
      </div>
      <div class="bg-white p-4 shadow-small rounded-lg card">
        <div class="form-group m-form__group grid grid-cols-4 justify-start gap-4 mb-6">
          <div class="p-0" v-for="item in filters" v-bind:key="item.id">
            <div class="" v-if="item.field === 'ecommerce'">
              <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Filter By Digital Platform</label>
              <Dropdown :options="digitalPlatforms" optionLabel="name" v-model="selectedDP" @change="searchByEcommerce()" :default="selectedDP" placeholder="Select Ecommerce" />
            </div>
            <div v-else-if="item.field != 'btn'" class="m-input-icon m-input-icon--left">
              <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Search By {{ item.label }}</label>
              <div class="relative">
                <TextField type="text" :enterKeyAction="onSearch" withIcon borderEnabled v-model="options[item.field]" :placeholder="item.label" />
                <div class="absolute left-3 top-1/2 transform -translate-y-1/2">
                  <Search />
                </div>
              </div>
            </div>
          </div>
        </div>
        <vue-good-table
          mode="remote"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: perPage,
            position: 'bottom',
            perPageDropdown: [5, 10],
            dropdownAllowAll: false,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All'
          }"
          :totalRows="totalRecords"
          :rows="data"
          :columns="columns"
          :sort-options="{
            enabled: false
          }"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange"
          :row-style-class="rowStyleClassFn"
        >
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field == 'btn'">
              <!--button
                            class="btn btn-primary "
                            type="button"
                            @click="preview(props.row.id)"
                          >
                            Preview
                          </button-->

              <button class="btn btn-primary" type="button" style="width: 100%; margin-bottom: 8px" @click="editMaster(props.row.id)">Edit</button>
              <button v-if="($store.getters['customer/customer'].role_id = 1)" class="btn btn-danger" type="button" style="width: 100%" @click="deleteMaster(props.row.id)">Delete</button>
            </div>
            <div v-else-if="props.column.field === 'nama_kelas'">
              <div class="nama-kelas">
                {{ props.formattedRow[props.column.field] }}
              </div>
            </div>
            <div v-else-if="props.column.field === 'certificate'">
              <a :href="props.formattedRow[props.column.field]" target="_blank" class="text-yellow font-semibold hover:underline">Preview</a>
            </div>
            <div v-else-if="props.column.field === 'completion_date'">
              <span style="display: block; white-space: nowrap">
                {{ formatDate(props.formattedRow[props.column.field]).date }}
              </span>
              <span style="display: block; font-size: 14px; color: #333">
                {{ formatDate(props.formattedRow[props.column.field]).time }}
              </span>
            </div>
            <div v-else-if="props.column.field === 'created_date'">
              <span style="display: block; white-space: nowrap">
                {{ formatDate(props.formattedRow[props.column.field]).date }}
              </span>
              <span style="display: block; font-size: 14px; color: #333">
                {{ formatDate(props.formattedRow[props.column.field]).time }}
              </span>
            </div>
            <span v-else-if="props.column.field == 'url'">
              <img
                :src="
                  props.row.url +
                  (($route.meta.testingName == 'h') | ($route.meta.testingName == 'i') | ($route.meta.testingName == 'j') | ($route.meta.testingName == 'k') | ($route.meta.testingName == 'z')
                    ? '?onlyAnnotation=0'
                    : '')
                "
                class="img-fluid"
                alt="Image"
              />
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Kelas',
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown')
  },
  mounted: function () {
    this.initData()
  },
  data: function () {
    return {
      searchTerm: null,
      options: {
        limit: 11,
        page: 1,
        sort: '',
        nama: ''
      },
      //columns: [],
      perPage: 10,
      showProgress: true,
      counterProgress: 0,
      modeForm: '',
      locked: false,
      unlockPassword: '',
      stillLoading: true,
      testingName: '',
      selectedDP: null
    }
  },
  methods: {
    ...mapActions('common', ['DIGITAL_PLATFORMS', 'showLoading', 'hideLoading']),
    searchByEcommerce() {
      this.options['ecommerce'] = this.selectedDP.code
      this.onSearch()
    },
    formatDate(date) {
      if (date) {
        return {
          date: moment(date).local().format('DD MMM YYYY'),
          time: moment(date).local().format('HH:mm:ss')
        }
      } else {
        return '-'
      }
    },
    initData() {
      this.modeForm = this.$route.meta.mode
      this.counterProgress = 0
      let paramsTemp = queryString.stringify({
        ...{
          name: null,
          sort: ''
        },
        ...this.options
      })
      this.$store
        .dispatch('reportecommerce/GET_LIST_REPORTECOMMERCE', {
          masterType: this.$route.meta.name,
          params: paramsTemp,
          customerId: this.customerId
        })
        .then(() => {
          this.counterProgress = 100
          this.showProgress = false
          this.stillLoading = false
        })
    },
    unlock() {
      if (this.unlockPassword == this.$store.getters['customer/customer'].password) {
        this.locked = false
      } else {
        alert('Password infalid')
      }
    },
    preview(masterId) {
      this.$router.push('/reportecommerce/' + masterId)
    },
    onPageChange(params) {
      this.options.limit = params.currentPerPage
      this.options.page = params.currentPage
      let paramsTemp = queryString.stringify({
        ...{
          limit: params.currentPerPage,
          page: params.currentPage,
          sort: ''
        },
        ...this.options
      })
      this.$store.dispatch('reportecommerce/GET_LIST_REPORTECOMMERCE', {
        masterType: this.$route.meta.name,
        params: paramsTemp,
        testingName: this.$route.meta.testingName,
        customerId: this.customerId
      })
    },

    onSearch(event) {
      this.options.page = 1
      let paramsTemp = queryString.stringify({
        ...{
          limit: null,
          page: null,
          nama: null,
          sort: '',
          testingName: this.$route.meta.testingName
        },
        ...this.options
      })
      this.$store.dispatch('reportecommerce/GET_LIST_REPORTECOMMERCE', {
        masterType: this.$route.meta.name,
        params: paramsTemp,
        testingName: this.$route.meta.testingName,
        customerId: this.customerId
      })
    },
    editMaster(masterId) {
      this.$router.push('/reportecommerce/edit/' + masterId)
    },
    deleteMaster(masterId) {
      let paramsTemp = queryString.stringify({
        ...{
          limit: null,
          page: null,
          nama: null,
          sort: '',
          testingName: this.$route.meta.testingName
        },
        ...this.options
      })

      this.$dialog
        .confirm('Are you sure you want to delete this?', {
          okText: 'Yes',
          cancelText: 'No',
          loader: true,
          animation: 'bounce'
        })
        .then((dialog) => {
          dialog.close()
          this.$store
            .dispatch('reportecommerce/DELETE_REPORTECOMMERCE', {
              masterType: this.$route.meta.name,
              masterId: masterId,
              testingName: this.$route.meta.testingName
            })
            .then((resp) => {
              if (resp.data.status == '200 OK') {
                // this.$swal(resp.data.message)
                this.$store.dispatch('reportecommerce/GET_LIST_REPORTECOMMERCE', {
                  masterType: this.$route.meta.name,
                  params: paramsTemp,
                  customerId: this.customerId
                })
              }
            })
        })
        .catch(function () {})
    },

    addData() {
      this.$router.push('/' + this.$route.meta.name + '-Add')
    },
    openDisplay() {
      this.$router.push('/display/' + this.$store.getters['customer/customer'].token_trx)
    },
    resetDisplay() {
      this.$store.dispatch('customer/RESET_TOKEN_TRX', {}).then((resp) => {
        if (resp.data.status == '200 OK') {
          // this.$swal(resp.data.status)
        }
      })
    },
    rowStyleClassFn(row) {
      let result = 'default'
      // if (row.id % 2 == 1 ){
      //     result='green'
      // } else if (row.id == 0 ){
      //     result='red'
      // }
      return result
    }
  },
  computed: {
    ...mapGetters('common', ['digitalPlatforms']),
    data: function () {
      return this.$store.getters['reportecommerce/list_reportecommerce'] ? this.$store.getters['reportecommerce/list_reportecommerce'] : []
    },
    totalRecords: function () {
      return this.$store.getters['reportecommerce/paginate'].total ? this.$store.getters['reportecommerce/paginate'].total : 0
    },
    columns: function () {
      return [
        { field: 'id', label: 'Id' },
        { field: 'ecommerce', label: 'Digital Platform' },
        { field: 'kode_voucher', label: 'Voucher Code' },
        { field: 'kode_kelas', label: 'Class Code' },
        { field: 'nama_kelas', label: 'Class Name' },
        { field: 'nama', label: 'Student Name' },
        { field: 'email', label: 'Email' },
        { field: 'certificate', label: 'Certificate' },
        // { field: "status", label: "Status" },
        { field: 'score', label: 'Score' },
        // { field: "rating", label: "Rating" },
        // { field: "ulasan", label: "Ulasan" },
        { field: 'completion_date', label: 'Completion Date' },
        { field: 'absensi_report_id', label: 'Attendance Report Id' },
        { field: 'customer_id', label: 'Customer Id' },
        { field: 'created_date', label: 'Created Date' }
        // { field: 'btn', label: 'Action', tdClass: 'custom-action', thClass: 'custom-action-head' }
      ]
    },
    filters: function () {
      return [
        // { field: 'ecommerce', label: 'Ecommerce' },
        { field: 'nama', label: 'Name' },
        { field: 'kode_voucher', label: 'Voucher Code' },
        { field: 'email', label: 'Email' },
        { field: 'status', label: 'Status' }
      ]
    },
    level_id: function () {
      return this.$store.getters['customer/customer'] ? this.$store.getters['customer/customer'].level_id : 0
    },
    customerId: function () {
      return localStorage.getItem('client')
    }
  },
  created: function () {
    // this.initData()
  },
  watch: {
    $route(to, from) {
      this.showProgress = true
      this.initData()
    }
  }
}
</script>
<style scoped lang="scss">
.red {
  background: linear-gradient(#f4f5f8, #e2133f);
}
.green {
  background: linear-gradient(#f4f5f8, #57c137);
}
.default {
  background: linear-gradient(#f4f5f8, #f1f3f6);
}
.nama-kelas {
  min-width: 300px;
}
.card {
  &::v-deep .vgt-table thead th {
    white-space: nowrap;
  }
}
</style>
