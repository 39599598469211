var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _c('div', {
    staticClass: "min-h-screen"
  }, [_vm._m(0), _c('div', {
    staticClass: "bg-white p-4 shadow-small rounded-lg card"
  }, [_c('div', {
    staticClass: "form-group m-form__group grid grid-cols-4 justify-start gap-4 mb-6"
  }, _vm._l(_vm.filters, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "p-0"
    }, [item.field === 'ecommerce' ? _c('div', {}, [_c('label', {
      staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
    }, [_vm._v("Filter By Digital Platform")]), _c('Dropdown', {
      attrs: {
        "options": _vm.digitalPlatforms,
        "optionLabel": "name",
        "default": _vm.selectedDP,
        "placeholder": "Select Ecommerce"
      },
      on: {
        "change": function change($event) {
          return _vm.searchByEcommerce();
        }
      },
      model: {
        value: _vm.selectedDP,
        callback: function callback($$v) {
          _vm.selectedDP = $$v;
        },
        expression: "selectedDP"
      }
    })], 1) : item.field != 'btn' ? _c('div', {
      staticClass: "m-input-icon m-input-icon--left"
    }, [_c('label', {
      staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
    }, [_vm._v("Search By " + _vm._s(item.label))]), _c('div', {
      staticClass: "relative"
    }, [_c('TextField', {
      attrs: {
        "type": "text",
        "enterKeyAction": _vm.onSearch,
        "withIcon": "",
        "borderEnabled": "",
        "placeholder": item.label
      },
      model: {
        value: _vm.options[item.field],
        callback: function callback($$v) {
          _vm.$set(_vm.options, item.field, $$v);
        },
        expression: "options[item.field]"
      }
    }), _c('div', {
      staticClass: "absolute left-3 top-1/2 transform -translate-y-1/2"
    }, [_c('Search')], 1)], 1)]) : _vm._e()]);
  }), 0), _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "pagination-options": {
        enabled: true,
        mode: 'pages',
        perPage: _vm.perPage,
        position: 'bottom',
        perPageDropdown: [5, 10],
        dropdownAllowAll: false,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        // for 'pages' mode
        allLabel: 'All'
      },
      "totalRows": _vm.totalRecords,
      "rows": _vm.data,
      "columns": _vm.columns,
      "sort-options": {
        enabled: false
      },
      "row-style-class": _vm.rowStyleClassFn
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field == 'btn' ? _c('div', [_c('button', {
          staticClass: "btn btn-primary",
          staticStyle: {
            "width": "100%",
            "margin-bottom": "8px"
          },
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              return _vm.editMaster(props.row.id);
            }
          }
        }, [_vm._v("Edit")]), (_vm.$store.getters['customer/customer'].role_id = 1) ? _c('button', {
          staticClass: "btn btn-danger",
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteMaster(props.row.id);
            }
          }
        }, [_vm._v("Delete")]) : _vm._e()]) : props.column.field === 'nama_kelas' ? _c('div', [_c('div', {
          staticClass: "nama-kelas"
        }, [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])]) : props.column.field === 'certificate' ? _c('div', [_c('a', {
          staticClass: "text-yellow font-semibold hover:underline",
          attrs: {
            "href": props.formattedRow[props.column.field],
            "target": "_blank"
          }
        }, [_vm._v("Preview")])]) : props.column.field === 'completion_date' ? _c('div', [_c('span', {
          staticStyle: {
            "display": "block",
            "white-space": "nowrap"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).date) + " ")]), _c('span', {
          staticStyle: {
            "display": "block",
            "font-size": "14px",
            "color": "#333"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).time) + " ")])]) : props.column.field === 'created_date' ? _c('div', [_c('span', {
          staticStyle: {
            "display": "block",
            "white-space": "nowrap"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).date) + " ")]), _c('span', {
          staticStyle: {
            "display": "block",
            "font-size": "14px",
            "color": "#333"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).time) + " ")])]) : props.column.field == 'url' ? _c('span', [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": props.row.url + (_vm.$route.meta.testingName == 'h' | _vm.$route.meta.testingName == 'i' | _vm.$route.meta.testingName == 'j' | _vm.$route.meta.testingName == 'k' | _vm.$route.meta.testingName == 'z' ? '?onlyAnnotation=0' : ''),
            "alt": "Image"
          }
        })]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-6 flex justify-between items-center"
  }, [_c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v("Report Ecommerce")])]);
}]

export { render, staticRenderFns }